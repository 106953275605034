import React, { Component } from 'react';
import { UploadProductCSVModal } from './upload_product_csv_modal';
import { Message } from "../global/models";
import { Ppeemployerv1ProductInfo, V1CurrencyConfig } from "../gen/typescript-axios";
import { V1ImportProductInfo } from "../gen/typescript-axios";
import { hasSpecialCharacters } from '../has_special_characters';
import { parseCurrencyToCents } from '../util/currencyFormatter';

interface Props {
  reloadProduct(): void
  pushFlashMessage(message: Message): void;
  currency_config: V1CurrencyConfig | undefined
}

interface State {
  isShowModal: boolean;
  csvData: V1ImportProductInfo[];
  ready: boolean;
}

class UploadProductCsv extends Component<Props, State> {
  private csvLink: React.RefObject<any>;
  private fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.state = {
      csvData: [],
      ready: false,
      isShowModal: false,
    };

    this.csvLink = React.createRef();
  }

  openUploadProductCSVModal() {
    this.setState({ isShowModal: true })
  }

  closeUploadProductCSVModal() {
    this.setState({ isShowModal: false })
  }

  clearCsvData() {
    this.setState({
      csvData: [],
      ready: false,
    });
  }

  openfile() {
    this.fileInputRef.current?.click();
  }

  handleFile = (file: File | null) => {
    this.clearCsvData();
    if (!file) {
      return;
    }

    if (file.type !== 'text/csv') {
      this.props.pushFlashMessage(new Message('Please select a CSV file.', 'error'));
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const content = event.target?.result as string;
        const contentWithoutTrailingWhitespace = content.trim();
        const lines = contentWithoutTrailingWhitespace.split(/\r\n|\n/);

        const expectedHeaders = [
          'Uuid', 'Name', 'Value', 'Manufacturer', 'Manufacturer Code', 'Supplier',
          'Supplier Code', 'Inspection Interval', 'Is Trackable'
        ];

        const headers = lines[0].split(',');

        if (!this.areHeadersValid(headers, expectedHeaders)) {
          this.props.pushFlashMessage(new Message('Invalid CSV file format. Please check the headers.', 'error'));
          return;
        }

        const csvData: Ppeemployerv1ProductInfo[] = lines
          .slice(1)
          .map((line) => {
            const columns = line.split(',');

            const sanitizedColumns = columns.map((col) => col.replace(/"/g, '').trim() || '');

            console.log('sanitizedColumns', sanitizedColumns);
            return {
              uuid: sanitizedColumns[0],
              name: sanitizedColumns[1],
              value_in_cents: parseCurrencyToCents(sanitizedColumns[2], this.props.currency_config),
              manufacturer: sanitizedColumns[3],
              manufacturer_code: sanitizedColumns[4],
              supplier: sanitizedColumns[5],
              supplier_code: sanitizedColumns[6],
              inspection_interval: Number(sanitizedColumns[7]) || 0,
              trackable: sanitizedColumns[8] == 'true' ? true : false,
            };
          });

        for (let csv of csvData) {
          if (hasSpecialCharacters(csv.name ?? '', csv.manufacturer ?? '', csv.manufacturer_code ?? '', csv.supplier ?? '', csv.supplier_code ?? '')) {
            this.props.pushFlashMessage(new Message('Product name, manufacturer, manufacturer code, supplier, or supplier code contains special characters. Please remove them and try again.', 'error'));
            return;
          }
        }

        this.setState({
          csvData,
          ready: true,
        });

        console.log('csvData', csvData);

        this.handleUpload();
      } catch (error) {
        this.props.pushFlashMessage(new Message('Error parsing CSV file, make sure it is valid, and try again.', 'error'));
      }
    };

    reader.readAsText(file);
  };

  areHeadersValid = (headers: string[], expectedHeaders: string[]): boolean => {
    const sanitizedExpectedHeaders = expectedHeaders.map(header => header.trim().replace(/"/g, ''));

    return headers.length === sanitizedExpectedHeaders.length &&
      headers.every((header, index) => header.trim().replace(/"/g, '') === sanitizedExpectedHeaders[index]);
  };


  prepareCsvHeader() {
    return [
      { label: 'Uuid', key: 'uuid' },
      { label: 'Name', key: 'name' },
      { label: 'Value', key: 'value' },
      { label: 'Manufacturer', key: 'manufacturer' },
      { label: 'Manufacturer Code', key: 'manufacturer_code' },
      { label: 'Supplier', key: 'supplier' },
      { label: 'Supplier Code', key: 'supplier_code' },
      { label: 'Inspection Interval', key: 'inspection_interval' },
      { label: 'Is Trackable', key: 'trackable' },
    ];
  }

  prepareCsvData() {
    const csvData = this.state.csvData.map(product => ({
      uuid: product.uuid,
      name: product.name,
      value: product.value_in_cents,
      manufacturer: product.manufacturer,
      manufacturer_code: product.manufacturer_code,
      supplier: product.supplier,
      supplier_code: product.supplier_code,
      inspection_interval: product.inspection_interval,
      trackable: product.trackable,
    }));

    return csvData;
  }

  handleUpload = () => {
    if (this.state.ready) {
      this.openUploadProductCSVModal();
    } else {
      this.props.pushFlashMessage(new Message('Please select a CSV file first.', 'error'));
      this.clearCsvData();
    }
  };

  render() {
    return (
      <div>
        <UploadProductCSVModal currency_config={this.props.currency_config} pushFlashMessage={this.props.pushFlashMessage}
          reloadProduct={this.props.reloadProduct}
          closeUploadProductCSVkModal={this.closeUploadProductCSVModal.bind(this)}
          isOpen={this.state.isShowModal}
          csvData={this.state.csvData} />
        <input key={Date.now()}
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          type="file"
          onChange={(e) => this.handleFile(e.target.files && e.target.files[0])} />
        <button className="btn btn-primary btn-sm" onClick={e => this.openfile()}>
          Batch Import Product(Upload CSV)
        </button>
      </div>
    );
  }
}

export { UploadProductCsv };

